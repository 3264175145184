import { render, staticRenderFns } from "./NakamalMapPopup.vue?vue&type=template&id=42c54fac&scoped=true"
import script from "./NakamalMapPopup.vue?vue&type=script&lang=js"
export * from "./NakamalMapPopup.vue?vue&type=script&lang=js"
import style0 from "./NakamalMapPopup.vue?vue&type=style&index=0&id=42c54fac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c54fac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VBadge,VBtn,VIcon,VImg,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
