import { render, staticRenderFns } from "./NakamalHeatmapSidebar.vue?vue&type=template&id=7f5e1aa1"
import script from "./NakamalHeatmapSidebar.vue?vue&type=script&lang=js"
export * from "./NakamalHeatmapSidebar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAlert,VContainer,VNavigationDrawer,VSwitch})
